document.addEventListener('DOMContentLoaded', function() {
    const titulo2 = document.getElementsByClassName('titulo2');
    const subtitulo = document.querySelector('.subtitulo');
    const fondo = document.querySelector('.imagenFondo');
    const numeros = document.getElementsByClassName('')
    const contenedor = document.getElementsByClassName('container')
    const animatedElement = document.querySelector(".timer");
    const animatedElement2 = document.querySelector(".aniNumero2");
    const verificacion = document.getElementsByClassName("verificacion")
    let telefono = window.matchMedia("(max-width: 430px)");
    let ventanaTablet = window.matchMedia("(max-width: 768px)");
    titulo2[0].classList.add('show')
    animacion = false
    if(ventanaTablet.matches){
    }
    setTimeout(function() {
        fondo.classList.add('segF')
        if(telefono.matches){
          titulo2[0].style.width = '150px'  
        }else{
          titulo2[0].style.width = '300px'
        }
        contenedor[0].style.backgroundColor = 'white' 
        setTimeout(function() {
            if(telefono.matches){
              titulo2[0].style.width = '250px'
            }
            else if(ventanaTablet.matches){
              titulo2[0].style.width = '500px'
            }else{
              titulo2[0].style.width = '800px'
            }
            fondo.style.transform = 'scale(1)' 
    
        }, 1000);

    }, 2000); // 3000 milisegundos = 3 segundos
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  
    function handleScroll() {
      if (isElementInViewport(animatedElement)) {
        if(animacion == false){
          function counterNumber() {
            $('div.timer').counterUp({
                delay: 5,
                time: 8000
            });
          }
          counterNumber()
        }
        animacion = true
      }
    }
    
    window.addEventListener('scroll', handleScroll);

  });
